import React from 'react'
import Layout from 'layouts/zh'
import { MAState } from '../../solutions/ma/index.zh'

const SF: React.FC = (props) => {
  return (
    <Layout {...props}>
      <MAState
        pageTitle="神策自动化运营|用户全流程智能推荐系统|SensorsRecommend"
        description="神策自动化运营系统，从用户行为数据的采集、建模、挖掘到效果分析，实现对用户“千人千面”的个性化内容推荐，改善用户体验，持续提升核心业务指标。"
        keywords="用户行为数据,千人千面,个性化内容推荐,改善用户体验,神策推荐,神策智能推荐,神策数据,神策,Sensors Recommend"
        saTitle="首页｜营销自动化解决方案专题页"
        bannerDesc="自动化营销平台帮助营销人员选定目标人群，制定并自动化地执行营销策略，实现多渠道精准触达，给用户提供个性化体验，从而提升营销效果。"
      />
    </Layout>
  )
}

export default SF
